import styled from '@emotion/styled';
import { IconType } from 'react-icons';

const SocialNetworkAncor = styled.a``;

const SocialNetworkButton = styled.button`
    opacity: 0.5;
    width: 48px;
    border-radius: 50%;
    border: none;
    height: 48px;
    margin: 6px 8px;

    :hover {
        cursor: pointer;
        opacity: 1;
        background-color: #dcdcdc;

        svg {
            fill: ${({ theme: { colors } }) => colors.secondary};
        }
    }
`;

type SocialNetworkLinkProps = {
    url: string;
    Icon: IconType;
};

const SocialNetworkLink = ({ url, Icon }: SocialNetworkLinkProps) => {
    return (
        <SocialNetworkAncor href={url} target='_blank'>
            <SocialNetworkButton>
                <Icon size={24} />
            </SocialNetworkButton>
        </SocialNetworkAncor>
    );
};

export { SocialNetworkLink };
