import styled from '@emotion/styled';
import { ActionButton } from 'design-system/button';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { breakpoints } from 'styles/theme';

const HambuguerLines = styled.span`
    display: block;
    height: 26px;
    width: 32px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
`;

const Line = styled.div<{ number: number; isOpen: boolean }>`
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: #0e2431;

    ${({ number, isOpen }) =>
        number === 1
            ? `
                transform-origin: 0% 0%;
                transition: transform 0.4s ease-in-out;
                ${isOpen ? 'transform: rotate(45deg);' : ''}
        `
            : number === 2
            ? `
                transition: transform 0.2s ease-in-out;
                ${isOpen ? 'transform: scaleY(0);' : ''}
        `
            : number === 3
            ? `
                transform-origin: 0% 100%;
                transition: transform 0.4s ease-in-out;
                ${isOpen ? 'transform: rotate(-45deg);' : ''}
        `
            : ''}
`;

const MenuItems = styled.ul<{ isOpen: boolean }>`
    padding-top: 48px;
    padding-bottom: 48px;
    margin-top: 48px;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
    width: 90%;
    position: absolute;
    transform: ${({ isOpen }) =>
        isOpen ? 'translate(40px);' : 'translate(-100%);'};
    display: flex;
    flex-direction: column;
    margin-left: -48px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    background-color: ${({ theme: { colors } }) =>
        colors.grey.sectionBackground};
    z-index: 2;
    border-radius: 8px;
    border: solid 2px ${({ theme: { colors } }) => colors.secondary};
`;

const MenuItem = styled.li`
    margin-bottom: 1.2rem;
    font-family: 'Chelsea';
    font-size: 18px;

    a {
        &.active {
            border-bottom: 4px solid ${(props) => props.theme.colors.primary};
        }

        &:hover {
            color: ${(props) => props.theme.colors.secondary};
        }
    }
`;

const Container = styled.div`
    ${breakpoints.notMobile} {
        display: none;
    }
`;

const HamburguerMenu = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onGoToDemo = () => {
        document.getElementById('formdemo')?.scrollIntoView({
            behavior: 'smooth'
        });
        document.getElementById('inputform')?.focus();
        setIsOpen(false);
    };

    return (
        <Container>
            <HambuguerLines onClick={() => setIsOpen((value) => !value)}>
                <Line number={1} isOpen={isOpen}></Line>
                <Line number={2} isOpen={isOpen}></Line>
                <Line number={3} isOpen={isOpen}></Line>
            </HambuguerLines>
            <MenuItems isOpen={isOpen}>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <NavLink
                        to='/'
                        className={({ isActive }) =>
                            isActive ? 'active' : undefined
                        }
                    >
                        Home
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <NavLink
                        to='/schools'
                        className={({ isActive }) =>
                            isActive ? 'active' : undefined
                        }
                    >
                        Piloto de Colegios
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <NavLink
                        to='/be-a-chessrocker'
                        className={({ isActive }) =>
                            isActive ? 'active' : undefined
                        }
                    >
                        Be a Chessrocker
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <NavLink
                        to='/about-us'
                        className={({ isActive }) =>
                            isActive ? 'active' : undefined
                        }
                    >
                        ¿Quienes somos?
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <ActionButton onClick={onGoToDemo}>
                        Pide una demo
                    </ActionButton>
                </MenuItem>
                <MenuItem onClick={() => setIsOpen(false)}>
                    <a href='https://rockyourchess-app.azurewebsites.net/'>
                        Iniciar sesión
                    </a>
                </MenuItem>
            </MenuItems>
        </Container>
    );
};

export { HamburguerMenu };
