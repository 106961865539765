import styled from '@emotion/styled';
import { SocialNetworkLink } from './the-footer/social-network-link';
import { BsLinkedin, BsInstagram } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import ajedreztomellosologo from '../../assets/images/ajedreztomelloso.png';
import eoi from '../../assets/images/logo-eoi.png';
import camaraComercio from '../../assets/images/Camara-logo-header.png';
import factoriaImpulsa from '../../assets/images/Factoria_impulsa_logo_color.png';
import ceei from '../../assets/images/ceei.png';
import { breakpoints } from 'styles/theme';

const FooterContainer = styled.footer`
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
        rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 50%) 0px 1px 10px 0px;
    background-color: #e7e7e7;
    margin-top: 24px;
    width: 100%;
`;

const SocialNetworkContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const CopyContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: ${({ theme: { colors } }) => colors.secondary};
    font-size: 18px;
    font-family: Roboto;
`;

const NoteContainer = styled.div`
    font-size: 18px;
    font-family: 'Roboto';
    line-height: 1.5;
    padding-top: 16px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin: 0 auto;
    padding: 8px;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${breakpoints.mobile} {
        flex-direction: column;
        gap: 16px;
        margin-top: 8px;
    }
`;

const TheFooter = () => {
    return (
        <FooterContainer>
            <div>
                <SocialNetworkContainer>
                    <SocialNetworkLink
                        url='mailto:info@rockyourchess.com'
                        Icon={MdEmail}
                    />
                    <SocialNetworkLink
                        url='https://www.linkedin.com/company/rockyourchess'
                        Icon={BsLinkedin}
                    />
                    <SocialNetworkLink
                        url='https://www.instagram.com/rockyourchess'
                        Icon={BsInstagram}
                    />
                </SocialNetworkContainer>
                <NoteContainer>
                    <p>
                        Rock Your Chess ha sido desarrollado en el espacio
                        Coworking de la EOI de Cuidad Real y ha participado en
                        Factoria Impulsa del CEEI de Ciudad Real.
                    </p>
                    <LogoContainer>
                        <a
                            href='https://www.eoi.es/es/espacios-coworking/ciudad-real'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={eoi}
                                alt='Escuela de Organización Industrial'
                                height={40}
                                style={{ padding: '8px' }}
                            />
                        </a>
                        <a
                            href='https://www.camaracr.org/'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={camaraComercio}
                                alt='Cámara de Comercio'
                                height={40}
                                style={{ padding: '8px' }}
                            />
                        </a>
                        <a
                            href='https://www.ceeicr.es'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={ceei}
                                alt='CEEI'
                                height={40}
                                style={{ padding: '8px' }}
                            />
                        </a>
                        <a
                            href='https://www.ceeicr.es/factoria-impulsa'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={factoriaImpulsa}
                                alt='Factoria Impulsa'
                                height={100}
                                style={{ padding: '8px' }}
                            />
                        </a>
                        <a
                            href='https://www.ajedreztomelloso.com'
                            target='_blank'
                            rel='noreferrer'
                        >
                            <img
                                src={ajedreztomellosologo}
                                alt='Ajedrez Tomelloso Logo'
                                height={100}
                            />
                        </a>
                    </LogoContainer>
                </NoteContainer>
                <CopyContainer>&copy; 2023 - Rock Your Chess</CopyContainer>
            </div>
        </FooterContainer>
    );
};

export { TheFooter };
