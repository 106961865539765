import { GlobalStyles } from '../styles/global-styles';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../styles/theme';
import styled from '@emotion/styled';
import { TheHeader } from './App/the-header';
import { TheFooter } from './App/the-footer';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React, { Suspense } from 'react';

const Container = styled.div`
    text-align: center;
`;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: true,
            refetchOnWindowFocus: false
        }
    }
});

const Main = React.lazy(() => import('./App/main-page'));
const SchoolsPage = React.lazy(() => import('./App/schools-page'));
const SchoolArticles = React.lazy(() => import('./App/school-articles'));
const WhoArePage = React.lazy(() => import('./App/about-us'));

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <Toaster position='top-right' />
                <GlobalStyles />

                <BrowserRouter>
                    <Container>
                        <TheHeader />
                        <Suspense fallback={null}>
                            <Routes>
                                <Route path='/' element={<Main />} />
                                <Route
                                    path='/articles'
                                    element={<SchoolArticles />}
                                />
                                <Route
                                    path='/schools'
                                    element={<SchoolsPage />}
                                />
                                <Route
                                    path='/about-us'
                                    element={<WhoArePage />}
                                />
                            </Routes>
                        </Suspense>
                        <TheFooter />
                    </Container>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    );
};

export default App;
