import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();

const appInsights: ApplicationInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '09040331-db78-47a0-b3fe-b8168124fd8f',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
    }
});

const initializeTracking = () => {
    // eslint-disable-next-line no-console
    console.log('tracking initialized');
    appInsights.loadAppInsights();
    appInsights.trackPageView();
};

const trackEvent = (name: string) => {
    appInsights.trackEvent({ name });
};

export { initializeTracking, trackEvent };
