import styled from '@emotion/styled';
import { NavLink, Link } from 'react-router-dom';
import { breakpoints } from 'styles/theme';
import logo from '../../assets/logo_ryc_sin_letras.png';
import { HamburguerMenu } from './the-header/hamburguer-menu';
import { ActionButton } from 'design-system/button';

const Header = styled.header`
    align-items: center;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: row;
    height: 90px;
    justify-content: start;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
        0 1px 10px 0 rgb(0 0 0 / 12%);
    margin-bottom: 16px;
    width: 100%;

    ${breakpoints.mobile} {
        padding: 0;
        margin: 0 auto;
        margin-bottom: 16px;
    }
`;

const Logo = styled.img`
    height: 80px;
    pointer-events: none;
`;

const Title = styled.h1`
    font-family: Chelsea;
    font-size: 24px;
`;

const ButtonsContainer = styled.nav`
    margin-left: auto;
    margin-right: 30px;

    a {
        font-family: Chelsea;
        font-size: 18px;
        padding: 8px;
        border-radius: 5px;
        margin-left: 8px;

        :hover {
            color: ${(props) => props.theme.colors.primary};
        }

        &.active {
            border-bottom: 4px solid ${(props) => props.theme.colors.primary};
        }
    }

    a.link-app {
        font-family: Roboto;
    }

    ${breakpoints.mobile} {
        display: none;
    }
`;

const ContainerLogo = styled.div`
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TheHeader = () => {
    const onGoToDemo = () => {
        document.getElementById('formdemo')?.scrollIntoView({
            behavior: 'smooth'
        });
        document.getElementById('inputform')?.focus();
    };

    return (
        <Header>
            <ContainerLogo>
                <HamburguerMenu />
                <Link to='/'>
                    <Logo src={logo} alt='Logo Rock Your Chess' />
                </Link>
            </ContainerLogo>
            <div>
                <Title>Rock Your Chess</Title>
            </div>
            <ButtonsContainer>
                <NavLink
                    to='/'
                    className={({ isActive }) =>
                        isActive ? 'active' : undefined
                    }
                >
                    Proyecto Rock Your Chess
                </NavLink>
                <NavLink
                    to='/articles'
                    className={({ isActive }) =>
                        isActive ? 'active' : undefined
                    }
                >
                    Blog
                </NavLink>
                <NavLink
                    to='/about-us'
                    className={({ isActive }) =>
                        isActive ? 'active' : undefined
                    }
                >
                    ¿Quienes somos?
                </NavLink>
                <ActionButton
                    width={200}
                    onClick={onGoToDemo}
                    style={{ marginLeft: 24 }}
                >
                    Pedir una demo
                </ActionButton>
                <a
                    href='https://rockyourchess-app.azurewebsites.net'
                    className='link-app'
                >
                    Iniciar Sesión
                </a>
            </ButtonsContainer>
        </Header>
    );
};

export { TheHeader };
