import { Global, css } from '@emotion/react';
import { customReset } from './custom-reset';

const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${customReset}

                body {
                    height: 100%;
                    margin: 0;
                    font-family: LibreBodoni;
                    font-size: 12px;
                }

                @font-face {
                    font-family: 'Chelsea';
                    font-style: normal;
                    font-weight: 400;
                    src: local(''),
                        url('/static/fonts/chelsea/ChelseaMarket-Regular.ttf')
                            format('truetype');
                }

                @font-face {
                    font-family: 'LibreBodoni';
                    font-style: normal;
                    font-weight: 400;
                    src: local(''),
                        url('/static/fonts/bodoni/LibreBodoni-Regular.ttf')
                            format('truetype');
                }

                @font-face {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    src: local(''),
                        url('/static/fonts/roboto/Roboto-Regular.ttf')
                            format('truetype');
                }

                @font-face {
                    font-family: 'Roboto-bold';
                    font-style: normal;
                    font-weight: 400;
                    src: local(''),
                        url('/static/fonts/roboto/Roboto-Medium.ttf')
                            format('truetype');
                }
            `}
        />
    );
};

export { GlobalStyles };
