export type Theme = {
    colors: {
        primary: string;
        secondary: string;
        grey: {
            sectionBackground: string;
            inactive: string;
        };
        validations: {
            error: string;
        };
    };
};

const theme: Theme = {
    colors: {
        primary: '#ef8905',
        secondary: '#6b1de1',
        grey: {
            sectionBackground: '#f1f1f1',
            inactive: 'rgb(115, 123, 145)'
        },
        validations: {
            error: '#dc3545'
        }
    }
};

const breakpoints = {
    notMobile: '@media (min-width: 1000px)',
    mobile: '@media (max-width: 1000px)',
    tablet: '@media (max-width: 1300px)'
};

export { theme, breakpoints };
