import styled from '@emotion/styled';

type ActionButtonProps = {
    width?: number;
    isSelected?: boolean;
};

const ActionButton = styled.button<ActionButtonProps>`
    background-color: ${({ theme }) => theme.colors.primary};
    color: white;
    padding: 5px 15px;
    border-radius: 5px;
    outline: 0;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0px 2px 2px lightgray;
    width: ${(props) => props.width ?? 250}px;
    transition: ease background-color 250ms;
    opacity: ${(props) => (props.isSelected ? 0.8 : 1)};
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        cursor: default;
        opacity: 0.7;
        background-color: grey;
    }
    font-family: Chelsea;
`;

export { ActionButton };
